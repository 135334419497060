var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "spot-board" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-select", {
                        attrs: { items: _vm.equipmentList, label: "Equipment" },
                        model: {
                          value: _vm.equipmentFilter,
                          callback: function ($$v) {
                            _vm.equipmentFilter = $$v
                          },
                          expression: "equipmentFilter",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: { type: "text", label: "Origin City" },
                        model: {
                          value: _vm.originFilter.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.originFilter, "city", $$v)
                          },
                          expression: "originFilter.city",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: { type: "text", label: "Destination City" },
                        model: {
                          value: _vm.destinationFilter.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.destinationFilter, "city", $$v)
                          },
                          expression: "destinationFilter.city",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: { type: "text", label: "Origin State" },
                        model: {
                          value: _vm.originFilter.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.originFilter, "state", $$v)
                          },
                          expression: "originFilter.state",
                        },
                      }),
                      _c("v-spacer"),
                      _c("v-text-field", {
                        attrs: { type: "text", label: "Destination State" },
                        model: {
                          value: _vm.destinationFilter.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.destinationFilter, "state", $$v)
                          },
                          expression: "destinationFilter.state",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.resetSearch()
                                },
                              },
                            },
                            [_vm._v(" Reset Search ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.laneHeaders,
              items: _vm.indexedLanes,
              expanded: _vm.expanded,
              "item-key": "id",
              "show-expand": "",
              loading: _vm.loading,
              height: "400",
              "fixed-header": "",
              "single-expand": "",
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
            },
            on: {
              "update:expanded": function ($event) {
                _vm.expanded = $event
              },
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDesc = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "expanded-item",
                fn: function ({ headers, item }) {
                  return [
                    _c(
                      "td",
                      { attrs: { colspan: headers.length } },
                      [
                        _c("get-loads", {
                          attrs: {
                            "shipper-city": item.shipperCity,
                            "shipper-state": item.shipperState,
                            "consignee-city": item.consigneeCity,
                            "consignee-state": item.consigneeState,
                            "equipment-type": item.equipmentType,
                            "pickup-date": item.pickupDate,
                            endpoint: "/loads/api/spot-board-lookup/",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.origin",
                fn: function ({ item }) {
                  return [
                    _c("p", [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          _vm._s(item.origin.city) +
                            ", " +
                            _vm._s(item.origin.state)
                        ),
                      ]),
                      _c("br"),
                      _c("span", { staticClass: "subtitle" }, [
                        _vm._v(_vm._s(item.origin.src_date_formatted)),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("v-card", [_c("v-card-title"), _c("carrier-matches")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }